import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import styles from './index.module.scss'
import { HomeBannerImgList } from '@/utils/listMap'

interface HomeBannerType {
  banner: Array<{ img: string; path: string }>
}

export default function HomeBanner({ banner, isMobile }: HomeBannerType & { isMobile: boolean }) {
  return (
    <div className={styles.homeBanner}>
      <Swiper
        slidesPerView={1.4}
        spaceBetween={30}
        loop={true}
        centeredSlides={true}
        breakpoints={{
          // 在移动设备上显示一个滑动项
          0: {
            slidesPerView: 1,
          },
          // 在大屏幕上显示三个滑动项
          1150: {
            slidesPerView: 1.4,
          },
          1470: {
            slidesPerView: 1.6,
          },
          1750: {
            slidesPerView: 1.8,
          },
        }}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        enabled={true}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
        className={styles.homeBanner_swiper}
      >
        {banner.map((item, index) => (
          <SwiperSlide key={index}>
            <a href={item.path}>
                {
                    isMobile ? <div className={styles.mobile}>
                        <img src={item.img} alt={item.path}/>
                    </div> : <div className={styles.pc}>
                        <img src={item.img} alt={item.path} />
                    </div>
                }
            </a>
          </SwiperSlide>
        ))}
        {banner.map((item, index) => (
          <SwiperSlide key={index}>
            <a href={item.path}>
                {
                    isMobile ? <div className={styles.mobile}>
                        <img src={item.img}/>
                        {/* <Image
                  width={300}
                  height={150}
                  src={HomeBannerImgList[index].src}
                  alt={item.path}
                  loading="lazy"
                  layout="responsive"
                /> */}
                    </div> :
                    <div className={styles.pc}>
                {/* <Image
                  width={300}
                  height={150}
                  src={item.img}
                  alt={item.path}
                  loading="lazy"
                  layout="responsive"
                /> */}
                <img src={item.img} alt={item.path} />
              </div>
                }
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
